import React from "react";
import {useState, useEffect} from 'react'
import { createContext } from "react";
import GlobalStyles from 'styles/GlobalStyles';


//My imports
import LandingPage from "LandingPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ContactUs from "pages/ContactUs";
import { ConsentProvider } from 'helpers/ConsentContext';

export const AdminContext = createContext()

export default function App() {

const [currentAdmin, setCurrentAdmin] = useState()

useEffect(() => {
  const admin_id = JSON.parse(localStorage.getItem("admin_id"))
  if (admin_id) {
    fetch('/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({admin_id: admin_id})
      })
    .then(res => {
      if (res.ok) {
        res.json().then(user => {
          setCurrentAdmin(user)});
      }else{
        console.log("not logged in")
      }
    });
  }
}, []);

//HANDLE LOGOUT
//=============
function logoutCurrentUser(){
  localStorage.removeItem("admin_id")
  setCurrentAdmin(null)
}

function handleLogin(admin){
  // console.log(admin[0].session_id, "admin.session_id")
  setCurrentAdmin(admin)
  localStorage.setItem("admin_id", JSON.stringify(admin.id))
}

  return (
    <>
      <GlobalStyles/>
      <ConsentProvider>
      <Router>
      <AdminContext.Provider value={{handleLogin, logoutCurrentUser, currentAdmin}}>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
        </AdminContext.Provider>
      </Router>
      </ConsentProvider>
    </>
  );
}
