// ConsentContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';

const ConsentContext = createContext();

export function loadGTM() {
    const gtmId = process.env.REACT_APP_GTM_ID; // Accessing environment variable
    if (gtmId) {
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmId);
      console.log('GTM loaded');
    } else {
      console.error('GTM ID is not defined');
    }
  }

export const useConsent = () => useContext(ConsentContext);

export const ConsentProvider = ({ children }) => {
  const [consentGiven, setConsentGiven] = useState(null);

  useEffect(() => {
    const consent = localStorage.getItem('userConsent');
    console.log("consent", consent)
    if (consent === 'accepted') {
        loadGTM();
    }
    setConsentGiven(consent);
  }, []);

  return (
    <ConsentContext.Provider value={{ consentGiven, setConsentGiven }}>
      {children}
    </ConsentContext.Provider>
  );
};
