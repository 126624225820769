import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Footer from "components/footers/FiveColumnDark";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800 text-left`}

  p {
    ${tw`mt-2 leading-loose`}
  }

  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }

  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }

  h3 {
    ${tw`text-xl font-bold mt-6`}
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}

      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  /* Adding styles for td and th */
  td,
  th {
    border: 1px solid black;
    padding: 10px;
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              This Privacy Policy is provided by Endell Point Ltd, a company
              registered in England and Wales under company number: 13465282
              with registered office Unit 6, 203 Kilburn High Road, London,
              England, NW6 7HY (‘we’, ‘our’ or ‘us’) for use of our products and
              services including our Endell Point app, website at{" "}
              <a href="https://www.endellpoint.co.uk">
                https://www.endellpoint.co.uk/
              </a>
              , and parcel collection point services (Services).
            </p>
            <p>
              We take your privacy very seriously. Please read this privacy
              policy carefully as it contains important information on how and
              why we collect, store, use and share any information relating to
              you (your personal data).
            </p>
            <p>
              It also explains your rights in relation to your personal data and
              how to contact us or the relevant regulator in the event you have
              a complaint. Our collection, storage, use and sharing of your
              personal data is regulated by law, including under the UK General
              Data Protection Regulation (UK GDPR).
            </p>
            <p>
              We are the controller of personal data obtained via the Services,
              meaning we are the organisation legally responsible for deciding
              how and for what purposes it is used.
            </p>

            <h1>2 WHAT THIS POLICY APPLIES TO</h1>
            <p>This privacy policy relates to your use of the Services only.</p>
            <p>
              The Services may link to or rely on other apps, websites, APIs or
              services owned and operated by us or by certain trusted third
              parties to enable us to provide you with Services. These other
              apps, websites, APIs or services may also gather information about
              you in accordance with their own separate privacy policies. For
              privacy information relating to these other apps, websites or
              services, please consult their privacy policies as appropriate.
              For more information see the section ‘Who we share your personal
              data with’ below.
            </p>

            <h1>3 PERSONAL DATA WE COLLECT ABOUT YOU</h1>
            <p>
              The personal data we collect about you depends on the particular
              activities carried out through the Services. We will collect and
              use the following personal data about you:
            </p>
            <br />

            <table border="1">
              <thead>
                <tr>
                  <th>Category of data</th>
                  <th>In more detail</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Identity and account data you input into the Services
                    Registration is mandatory in order to use the Services
                  </td>
                  <td>
                    <ul>
                      <li>Your name and email address</li>
                      <li>
                        Your account details, such as username and password
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Data collected when you use specific functions in the
                    Services
                  </td>
                  <td>
                    Data you store online with us using the Services including
                    your usage history or preferences (while such data may not
                    always be personal data as defined at law in all cases we
                    will assume it is and treat it in accordance with this
                    policy as if it were)
                  </td>
                </tr>
                <tr>
                  <td>
                    Data collected when you permit the collection of location
                    data
                  </td>
                  <td>
                    Details of your location with a high degree of precision,
                    see the section ‘Location services/data’ below data
                    concerning location is collected when using the Maps
                    function of the Services
                  </td>
                </tr>
                <tr>
                  <td>
                    Other data the Services collects automatically when you use
                    it
                  </td>
                  <td>
                    <ul>
                      <li>
                        Your activities on, and use of, the Services which
                        reveal your preferences, interests or manner of use of
                        the Services and the times of use
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              If you do not provide personal data we ask for where it is
              required, including the geo-localisation services, it may prevent
              us from providing services and/or the Services to you.
            </p>
            <p>
              We collect and use this personal data for the purposes described
              in the section ‘How and why we use your personal data’ below.
            </p>

            <h1>4 SENSITIVE DATA</h1>
            <p>
              Sensitive personal data (also known as special category data)
              means information related to personal data revealing racial or
              ethnic origin; political opinions; religious or philosophical
              beliefs; trade union membership; genetic data; biometric data
              (where used for identification purposes); data concerning health;
              data concerning a person’s sex life; and data concerning a
              person’s sexual orientation.
            </p>
            <p>
              Please note that we do not knowingly or intentionally collect
              sensitive personal data or information about criminal convictions
              from individuals and that you should not submit sensitive data to
              us.
            </p>
            <p>
              If, however, you do submit sensitive data to us, such as if you
              make this sensitive data available to other users of the Services
              (such as by publishing it through any forum feature we may make
              available from time to time) we will assume that you have
              purposefully made any such sensitive data manifestly public.
            </p>

            <h1>5 LOCATION SERVICES/DATA</h1>
            <p>
              The Services will request your consent to use location services to
              precisely identify your location each session (i.e. each time the
              Services is opened or has been placed in the background for more
              than 30 seconds). We require access to that data in order to
              provide you with accurate listing of your closeby Services
              locations.
            </p>
            <p>
              If you do not provide your consent, you may use the Services but
              that will mean the “location services” feature on the Services
              will not be available. To withdraw your consent at any time you
              can turn off the localisation permissions for our app on your
              device.
            </p>
            <p>
              The location services in the Services will not operate unless
              location services/data are generally enabled on your device. You
              may disable such functionality at any time by turning your
              device’s location on “off” using the device’s settings app. When
              you allow your device to use location services/data, data will
              also be collected by Google in accordance with their Privacy
              Policy, as this is the map service we integrate to our app.
            </p>
            <p>
              We exert no control over Google’s Privacy Policy and we therefore
              recommend that you consult their privacy policy for further
              information on how Google protects personal data please visit
              their site -{" "}
              <a href="https://policies.google.com/privacy?hl=en-US">
                https://policies.google.com/privacy?hl=en-US
              </a>
              . For more information see the section ‘Who we share your personal
              data with’ below.
            </p>

            <h1>6 HOW YOUR PERSONAL DATA IS COLLECTED</h1>
            <p>
              We collect personal data from you directly when you sign up to the
              Services, contact us directly or reach out to us via social media,
              or indirectly, such as your activity while using the Services.
            </p>
            <p>
              At this point in time, we do not collect any personal information
              about you using cookies or similar technologies. If this were to
              change and if we were to decide to collect personal data about you
              using cookies, you would be notified of this change by means of
              in-app notification, with detailed information regarding our use
              of cookies and similar technologies to be made available in the
              relevant cookies policy.
            </p>

            <h1>7 HOW AND WHY WE USE YOUR PERSONAL DATA</h1>
            <p>
              Under data protection law, we can only use your personal data if
              we have a proper reason, e.g.:
            </p>
            <p>(i) where you have given consent</p>
            <p>(ii) to comply with our legal and regulatory obligations</p>
            <p>
              (iii) for the performance of a contract with you or to take steps
              at your request before entering into a contract, or
            </p>
            <p>(iv) for our legitimate interests or those of a third party</p>
            <p>
              A legitimate interest is when we have a business or commercial
              reason to use your information, so long as this is not overridden
              by your own rights and interests. We will carry out an assessment
              when relying on legitimate interests, to balance our interests
              against your own. You can obtain details of this assessment by
              contacting us (see ‘How to contact us’ below).
            </p>
            <p>
              The table below explains what we use your personal data for and
              why.
            </p>
            <table border="1">
              <thead>
                <tr>
                  <th>What we use your personal data for</th>
                  <th>Our reasons</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Create and manage your account with us</td>
                  <td>
                    To perform our contract with you or to take steps at your
                    request before entering into a contract
                  </td>
                </tr>
                <tr>
                  <td>
                    Providing services and/or the functionalities of the
                    Services to you
                  </td>
                  <td>
                    Depending on the circumstances:
                    <ul>
                      <li>
                        to perform our contract with you or to take steps at
                        your request before entering into a contract (in this
                        case, the contract means the Terms and Conditions of Use
                        which apply to the Services)
                      </li>
                      <li>
                        the Maps Function will use data relating to your
                        location only based on your consent as described in
                        ‘Location services/data’ (above)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    To enforce legal rights or defend or undertake legal
                    proceedings
                  </td>
                  <td>
                    Depending on the circumstances:
                    <ul>
                      <li>
                        to comply with our legal and regulatory obligations
                      </li>
                      <li>
                        in other cases, for our legitimate interests or those of
                        a third party, i.e. to protect our business, interests
                        and rights or those of others
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Communications with you not related to marketing, including
                    about changes to our terms or policies or changes to the
                    Services or service or other important notices
                  </td>
                  <td>
                    Depending on the circumstances:
                    <ul>
                      <li>
                        to comply with our legal and regulatory obligations
                      </li>
                      <li>
                        in other cases, for our legitimate interests or those of
                        a third party, i.e., to provide the best service to you
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Protect the security of systems and data</td>
                  <td>
                    To comply with our legal and regulatory obligations we may
                    also use your personal data to ensure the security of
                    systems and data to a standard that goes beyond our legal
                    obligations, and in those cases our reasons are for our
                    legitimate interests or those of a third party, i.e., to
                    protect systems and data and to prevent and detect criminal
                    activity that could be damaging for you and/or us
                  </td>
                </tr>
                <tr>
                  <td>
                    Operational reasons, such as improving efficiency, training,
                    and quality control or to provide support to you
                  </td>
                  <td>
                    For our legitimate interests or those of a third party,
                    i.e., to be as efficient as we can so we can deliver the
                    best service to you
                  </td>
                </tr>
                <tr>
                  <td>
                    Statistical analysis to help us manage our business, e.g.,
                    in relation to our performance, customer base, app and
                    functionalities and offerings or other efficiency measures
                  </td>
                  <td>
                    For our legitimate interests or those of a third party, i.e.
                    to be as efficient as we can so we can deliver the best
                    service to you and improve and develop our app
                  </td>
                </tr>
                <tr>
                  <td>Updating and enhancing user records</td>
                  <td>
                    Depending on the circumstances:
                    <ul>
                      <li>
                        to perform our contract with you or to take steps at
                        your request before entering into a contract (in this
                        case, the contract means the Terms and Conditions of Use
                        which apply to the Services)
                      </li>
                      <li>
                        to comply with our legal and regulatory obligations
                      </li>
                      <li>
                        where neither of the above apply, for our legitimate
                        interests or those of a third party, eg making sure that
                        we can keep in touch with our customers about their
                        accounts and new products or functionalities related to
                        the Services and our services
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Depending on the circumstances:
                    <ul>
                      <li>
                        to perform our contract with you or to take steps at
                        your request before entering into a contract (in this
                        case, the contract means the Terms and Conditions of Use
                        which apply to the Services)
                      </li>
                      <li>
                        to comply with our legal and regulatory obligations
                      </li>
                      <li>
                        where neither of the above apply, for our legitimate
                        interests or those of a third party, e.g. making sure
                        that we can keep in touch with our customers about their
                        accounts and new products or functionalities related to
                        the Services and our services
                      </li>
                    </ul>
                  </td>
                  <td>To comply with our legal and regulatory obligations</td>
                </tr>
                <tr>
                  <td>
                    To share your personal data with members of our group and
                    third parties in connection with a significant corporate
                    transaction or restructuring, including a merger,
                    acquisition, asset sale, initial public offering or in the
                    event of our insolvency. In such cases information will be
                    anonymised where possible and only shared where necessary
                  </td>
                  <td>
                    Depending on the circumstances:
                    <ul>
                      <li>
                        to comply with our legal and regulatory obligations
                      </li>
                      <li>
                        in other cases, for our legitimate interests or those of
                        a third party, i.e., to protect, realise or grow the
                        value in our business and assets
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              See ‘Who we share your personal data with’ for further information
              on the steps we will take to protect your personal data where we
              need to share it with others.
            </p>

            <h1>8 CHILDREN</h1>
            <p>
              Our Services are not targeted at children. We do not knowingly
              collect or process personal information of any person under 13
              years old. If we become aware that we have collected personal
              information of a person under 13 years old, we will make
              reasonable efforts to delete it. If you believe we might have any
              information from any person under 13 years old, please contact us
              at hello@endellpoint.co.uk so that we can take steps to remove
              their personal information.
            </p>

            <h1>9 MARKETING</h1>
            <p>
              We intended to use email and in-app marketing to inform you of our
              services such as promotions.
            </p>
            <p>
              We will always ask you for your consent before doing sending you
              marketing communications, except where you have explicitly
              opted-in to receiving email marketing from us in the past or
              except where you were given the option to opt-out of email
              marketing when you initially signed up for your account with us
              and you did not do so.
            </p>
            <p>
              You will have the right to opt out of receiving marketing
              communications at any time by:
            </p>
            <ul>
              <li>contacting us at hello@endellpoint.co.uk</li>
              <li>
                using the ‘unsubscribe’ link included in all marketing emails
                you may have received from us
              </li>
            </ul>
            <p>
              We will always treat your personal data with the utmost respect
              and never sell or share it with other organisations for marketing
              purposes.
            </p>
            <p>
              For more information on your right to object at any time to your
              personal data being used for marketing purposes, see ‘Your rights’
              below.
            </p>
            <h1>10 WHO WE SHARE YOUR PERSONAL DATA WITH</h1>
            <p>
              We routinely share personal data with service providers we use to
              help us run our business or provide the services or
              functionalities in the Services, including developers, cloud
              storage providers, Google, Inc. for Google Maps and other map
              applications and platforms for location functionality. We exert no
              control over Google’s or other third party applications’ and
              platforms’ Privacy Policy and we therefore recommend that you
              consult their privacy policy for further information on how they
              protect personal data.
            </p>
            <p>
              We only allow service providers to handle your personal data if we
              are satisfied they take appropriate measures to protect your
              personal data. We also impose contractual obligations on service
              providers to ensure they can only use your personal data to
              provide services to us and to you.
            </p>
            <p>
              We or the third parties mentioned above may occasionally also need
              to share your personal data with:
            </p>
            <ul>
              <li>
                logistics support companies necessary to provide the Services
              </li>
              <li>
                external auditors, e.g. in relation to the audit of our accounts
                and our company —the recipient of the information will be bound
                by confidentiality obligations
              </li>
              <li>
                professional advisors (such as lawyers and other advisors)—the
                recipient of the information will be bound by confidentiality
                obligations
              </li>
              <li>
                law enforcement agencies, courts or tribunals and regulatory
                bodies to comply with legal and regulatory obligations
              </li>
              <li>
                other parties in connection with a significant corporate
                transaction or restructuring, including a merger, acquisition,
                asset sale, initial public offering or in the event of our
                insolvency—usually, information will be anonymised but this may
                not always be possible, however, the recipient of the
                information will be bound by confidentiality obligations
              </li>
            </ul>
            <p>
              If you would like more information about who we share our data
              with and why, please contact us (see ‘How to contact us’ below).
            </p>
            <p>
              We will not share your personal data with any other third party.
            </p>

            <h1>11 HOW LONG YOUR PERSONAL DATA WILL BE KEPT</h1>
            <p>
              We will keep your personal data for as long as you have an active
              account with us and for a period of up to 6 years thereafter to
              comply with any accounting or legal obligations including in the
              event of the pursuit or defence of legal claims. Once you have
              closed your account with us, we will move your personal data to a
              separate database so that only key stakeholders in our business on
              a ‘need to know basis’ have access to such data.
            </p>
            <p>
              Following the end of the aforementioned retention period, we will
              delete or anonymise your personal data.
            </p>

            <h1>12 TRANSFERRING YOUR PERSONAL DATA OUT OF THE UK</h1>
            <p>
              Our app developers and their services are located in India, we
              comply with applicable UK laws designed to ensure the continued
              protection and privacy of your personal data. Any updated
              destinations to which we send your personal data, would be
              indicated in the present section and notified to you in accordance
              with the section on ‘Change to this privacy policy’ below.
            </p>
            <p>
              Furthermore, under UK data protection laws, we can only transfer
              your personal data to a country outside the UK where: the UK
              government has decided the particular country ensures an adequate
              level of protection of personal data (known as an ‘adequacy
              regulation’) further to Article 45 of the UK GDPR; there are
              appropriate safeguards in place, together with enforceable rights
              and effective legal remedies for you; or a specific exception
              applies under relevant data protection law. Accordingly, if we
              were to start transferring your personal data from the UK to:
            </p>
            <ul>
              <li>
                The EEA: we would rely on the adequacy finding granted by the UK
                to the EU under the Withdrawal Agreement to do; for any
                transfers from the EU to the UK, we would rely on the adequacy
                regulation granted to the UK under the Adequacy Decision.
              </li>
              <li>
                Any country located outside the UK/EEA: we would rely on
                appropriate safeguards under the UK GDPR, such as by including
                the relevant Standard Contractual Clauses in our data processing
                agreements.
              </li>
            </ul>
            <p>
              In the event we could not or choose not to continue to rely on
              either of those mechanisms at any time we would not transfer your
              personal data outside the UK unless we could do so on the basis of
              an alternative mechanism or exception provided by UK data
              protection law.
            </p>

            <h1>13 YOUR RIGHTS</h1>
            <p>
              You generally have the following rights, which you can usually
              exercise free of charge. For more information regarding these
              rights, please visit the ICO website{" "}
              <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/">
                here
              </a>
              .
            </p>
            <table>
              <thead>
                <tr>
                  <th>Right</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Access to a copy of your personal data</td>
                  <td>
                    The right to be provided with a copy of your personal data.
                  </td>
                </tr>
                <tr>
                  <td>Correction (also known as rectification)</td>
                  <td>
                    The right to require us to correct any mistakes in your
                    personal data.
                  </td>
                </tr>
                <tr>
                  <td>Erasure (also known as the right to be forgotten)</td>
                  <td>
                    The right to require us to delete your personal data—in
                    certain situations.
                  </td>
                </tr>
                <tr>
                  <td>Restriction of use</td>
                  <td>
                    The right to require us to restrict use of your personal
                    data in certain circumstances, e.g. if you contest the
                    accuracy of the data.
                  </td>
                </tr>
                <tr>
                  <td>Data portability</td>
                  <td>
                    The right to receive the personal data you provided to us,
                    in a structured, commonly used and machine-readable format
                    and/or transmit that data to a third party—in certain
                    situations.
                  </td>
                </tr>
                <tr>
                  <td>To object to use</td>
                  <td>
                    The right to object:
                    <br />
                    ● at any time to your personal data being used for direct
                    marketing (including profiling)
                    <br />● in certain other situations to our continued use of
                    your personal data, e.g. where we use your personal data for
                    our legitimate interests.
                  </td>
                </tr>
                <tr>
                  <td>
                    Not to be subject to decisions without human involvement
                  </td>
                  <td>
                    The right not to be subject to a decision based solely on
                    automated processing (including profiling) that produces
                    legal effects concerning you or similarly significantly
                    affects you We do not make any such decisions based on data
                    collected by the Services.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              For further information on each of those rights, including the
              circumstances in which they do and do not apply, please contact us
              (see ‘How to contact us’ below). You may also find it helpful to
              refer to the guidance from the UK’s Information Commissioner on
              your rights under the UK GDPR.
            </p>
            <p>
              If you would like to exercise any of those rights, please complete
              a request form—available on our website at{" "}
              <a href="https://www.endellpoint.co.uk/contact">
                https://www.endellpoint.co.uk/contact
              </a>{" "}
              or email, call or write to us—see below: ‘How to contact us’. When
              contacting us please:
            </p>
            <ul>
              <li>
                provide enough information to identify yourself (e.g., your full
                name and username) and any additional identity information we
                may reasonably request from you, and
              </li>
              <li>
                let us know which right(s) you want to exercise and the
                information to which your request relates
              </li>
            </ul>

            <h1>14 KEEPING YOUR PERSONAL DATA SECURE</h1>
            <p>
              We have appropriate security measures to prevent personal data
              from being accidentally lost, or used or accessed unlawfully. We
              limit access to your personal data to those who have a genuine
              business need to access it.
            </p>
            <p>
              We also have procedures in place to deal with any suspected data
              security breach. We will notify you and any applicable regulator
              of a suspected data security breach where we are legally required
              to do so.
            </p>
            <p>
              If you want detailed information from Get Safe Online on how to
              protect your information and your computers and devices against
              fraud, identity theft, viruses and many other online problems,
              please visit{" "}
              <a href="https://www.getsafeonline.org">www.getsafeonline.org</a>.
              Get Safe Online is supported by HM Government and leading
              businesses.
            </p>

            <h1>15 HOW TO COMPLAIN</h1>
            <p>
              Please contact us if you have any queries or concerns about our
              use of your information (see below ‘How to contact us’). We hope
              we will be able to resolve any issues you may have.
            </p>
            <p>
              You also have the right to lodge a complaint with the Information
              Commissioner.
            </p>
            <p>
              The Information Commissioner can be contacted at{" "}
              <a href="https://ico.org.uk/make-a-complaint">
                https://ico.org.uk/make-a-complaint
              </a>{" "}
              or telephone: 0303 123 1113.
            </p>

            <h1>16 CHANGES TO THIS PRIVACY POLICY</h1>
            <p>
              We may change this privacy policy from time to time. When we make
              significant changes we will take steps to inform you, for example
              via the Services or by other means, such as email.
            </p>

            <h1>17 HOW TO CONTACT US</h1>
            <p>
              You can contact us by post, email or telephone if you have any
              questions about this privacy policy or the information we hold
              about you, to exercise a right under data protection law or to
              make a complaint.
            </p>
            <p>Our contact details are shown below:</p>
            <ul>
              <li>Unit 6, 203 Kilburn High Road, London, England, NW6 7HY</li>
              <li>
                <a href="mailto:hello@endellpoint.co.uk">
                  hello@endellpoint.co.uk
                </a>
              </li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
        <Footer/>
      </Container>
    </>
  );
};
