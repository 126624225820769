// CommonLayout.js

import React from 'react';
import { useConsent } from 'helpers/ConsentContext';
import ConsentModal from 'components/modals/ConsentModal';
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";

const CommonLayout = ({ children }) => {
  const { consentGiven, setConsentGiven } = useConsent();

  return (
    <>
      <Header />
      {/* Content passed from individual pages */}
      {children}

      {/* Consent Modal that appears when consent is not given */}
      {consentGiven === null && <ConsentModal onConsent={setConsentGiven} />}

      <Footer />
    </>
  );
};

export default CommonLayout;
