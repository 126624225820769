import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from  "styled-components/macro";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-white`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url("https://images.pexels.com/photos/5025667/pexels-photo-5025667.jpeg");
  ${tw`bg-yellow-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl text-center mb-8 sm:text-5xl md:text-6xl lg:text-5xl lg:text-left font-black leading-none`;
const Paragraph = tw.p`max-w-xl my-5 lg:my-1 sm:text-lg lg:text-base xl:text-lg leading-loose`;

export default ({
  heading = (
    <>
      Hope to Serve You Again Soon
      <wbr />
      <br />
      <span tw="text-yellow-500 mt-4 block">@ Endell Point</span>
    </>
  ),
  paragraph1 = "Our parcel collection points will save you from the frustration of delayed or missed deliveries, making your online shopping hassle-free.",
  paragraph2 = "Endell Point receives and keeps safe your parcels for up to 2 weeks, until you are ready to collect at your convenience.",
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          {/* <StyledHeader links={navLinks} collapseBreakpointClass="sm" /> */}
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{paragraph1}</Paragraph>
            <Paragraph>{paragraph2}</Paragraph>
          </Content>
        </LeftColumn>
        <RightColumn></RightColumn>
      </TwoColumn>
    </Container>
  );
};
