import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
// const Container = tw.div`relative  mx-5 max-w-screen-xl mx-auto`;

export default () => {
  return (
      <>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <ContactUsForm />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </>
  );
};
