import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faMobileScreenButton, faPaste, faPersonWalking, faQrcode, faShoePrints } from "@fortawesome/free-solid-svg-icons";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { faTruck } from "@fortawesome/free-solid-svg-icons";

// const Container = tw.div`relative bg-yellow-400 -mx-8 px-8 text-gray-700`;
const Container = styled.div`
  ${tw`relative -mx-8 px-8 text-gray-700`}
  background-color: #ffed00;
`;


const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-12`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-700`;
const Heading = tw(SectionHeading)`w-full text-gray-800`;
const Description = tw(SectionDescription)`w-full text-center text-gray-600`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-4`}
  .imageContainer {
    ${tw`bg-gray-100 text-center p-6 flex-shrink-0`}
    border-radius: 50%;
    img {
      ${tw`w-4 h-4`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-600 leading-snug`}
  }
};`

const IOSButton = styled.button`
  ${tw`relative bg-gray-200 p-4`} /* Add your button styles here */
  &:after {
    content: "Coming Soon";
    ${tw`absolute top-0 left-0 w-full h-full flex items-center justify-center text-white bg-black bg-opacity-50`}
    pointer-events: none; /* Ensures the button is still clickable */
  }
`;

export default ({
  cards = null,
  subheading = "",
  heading = "How It Works",
  description = "When shopping online, use Your Name and your local Endell Point address instead of your home address to check out. Then:"
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  // Images Original
  // import ShieldIconImage from "images/shield-icon.svg";
  // import SupportIconImage from "images/support-icon.svg";
  // import CustomizeIconImage from "images/customize-icon.svg";
  // import FastIconImage from "images/fast-icon.svg";
  // import ReliableIconImage from "images/reliable-icon.svg";
  // import SimpleIconImage from "images/simple-icon.svg";

  const defaultCards = [
    {
      icon: faMobileScreenButton,
      title: "1. Open the Endell Point app",
      description: "Our app is coming soon to the App Store and Google Play Store – stay tuned!"
    },
    { 
      icon: faPaste, 
      title: "2. Copy & Paste your parcel Tracking ID to the app",
      description: "You can find your Tracking ID in an e-mail or SMS from the retailer "
     },
    { 
      icon: faQrcode, 
      title: "3. Get a unique collection QR code in the app",
      description: "You will use this to collect your parcel from your local Endell Point."
    },
    { icon: faBell,
      title: "4. Receive an app notification when your parcel arrives",
      description: "Your parcel will be marked as ARRIVED when it has been delivered to your local Endell Point."
    },
    { icon: faShoePrints,
      title: "5. Visit your local Endell Point",
      description: "Directions and opening hours are available on your parcel page in the app."
    },
    { icon: faBox,
      title: "6. Pay £1.35* and collect!",
      description: "* Opening offer, valid until for the first 3 months after which a standard rate of £1.50 will apply. We are CASHLESS"
    },

  ];

  if (!cards) cards = defaultCards;

  return (
    <div className="w-100">
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <FontAwesomeIcon 
                icon={card.icon} 
                style={{color: "#333333",}}
                size="xl"/>
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}

        <Card>
              <span className="textContainer" tw="text-center">
                <span className="title">{"You can also use our service without the app"}</span>
                <p>
                <br/>
                Simply ship your parcel to your local Endell Point,
                <br/> and come to collect with your Tracking ID on hand. 
                <br/>
                <br/>
                Just as easy!
                </p>
              </span>
            </Card>

      </ThreeColumnContainer>
    </Container>
    </div>
  );
};
