import { useState, useEffect } from "react";
import CommonLayout from "./CommonLayout"; // Import the common layout component

// Import your components
import Hero from "components/hero/FullWidthWithImage";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground";
import Faq from "components/faqs/SingleCol";
import Blog from "components/blogs/PopularAndRecentBlogPosts";
// Footer will be included in CommonLayout, so no need to import here

function LandingPage() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        fetch("/news_landing", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(res => {
            if(res.ok){
                res.json().then(data => {
                    setNews(data);
                });
            }
        });
    }, []);

    return (
        <CommonLayout> {/* Wrap page content with CommonLayout */}
            <Hero />
            <Features />
            <Faq />
            {
                news.length > 0 ? 
                <Blog news={news} /> :
                null
            }
            {/* Footer is included in CommonLayout, so no need to include it here */}
        </CommonLayout>
    );
}

export default LandingPage;




// import { useState, useEffect } from "react";

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground";
// import Faq from "components/faqs/SingleCol";
// import Blog from "components/blogs/PopularAndRecentBlogPosts";
// import Footer from "components/footers/FiveColumnDark"

// function LandingPage(){

//     const [news, setNews] = useState([])

//     useEffect(() => {
//         fetch("/news_landing",
//         {
//         method: "GET",
//         headers: {
//             "Content-Type": "application/json",
//         }
//         })
//         .then(res => {
//             if(res.ok){
//                 res.json().then(data => {
//                     setNews(data)
//                 })
//             }
//         })
//     }, [])
    
//     return (
//         <>
//         <Hero />
//         <Features />
//         <Faq/>
//         {
//             news.length > 0 ? 
//             <Blog news={news}/>
//             :
//             null
//         }
//         <Footer/>
//         </>
//     );
// }

// export default LandingPage;