import React from 'react';

const modalStyle = {
  position: 'fixed', // Fixed position to cover the whole screen
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000, // High z-index to be on top of other content
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: '40px',
  borderRadius: '5px',
  maxWidth: '500px',
  textAlign: 'center',
};

const buttonStyle = {
    // Make buttons look nice and yellow
    backgroundColor: '#ffed00',
    // Text black
    color: '#000',
    border: 'none',
    padding: '10px 50px',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '30px 10px 0 10px',
};

const ConsentModal = ({ onConsent }) => {
  const handleAccept = () => {
    localStorage.setItem('userConsent', 'accepted');
    onConsent('accepted');
  };

  const handleReject = () => {
    localStorage.setItem('userConsent', 'rejected');
    onConsent('rejected');
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <p>We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic. Do you consent to our cookies if you continue to use our website?</p>
        <button style={buttonStyle} onClick={handleAccept}>Accept</button>
        <button style={buttonStyle} onClick={handleReject}>Reject</button>
      </div>
    </div>
  );
};

export default ConsentModal;
