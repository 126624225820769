import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800 text-left`}

  p {
    ${tw`mt-2 leading-loose`}
  }

  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }

  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }

  h3 {
    ${tw`text-xl font-bold mt-1 text-gray-600`}
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}

      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  /* Adding styles for td and th */
  td,
  th {
    border: 1px solid black;
    padding: 10px;
  }
`;

const Form = tw.form`pt-5 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-[#ffed00]`

const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`min-h-24`}
`

// Make this button follow these styles:
// `
//   ${tw`lg:mx-0 px-8 py-3 rounded text-gray-700 hocus:bg-blue-900 hocus:text-gray-200 focus:shadow-outline border-b-0`}
//   background-color: #ffed00; /* Custom background color */
// `;
export const SubmitButton = styled(PrimaryButtonBase)`
  ${tw`inline-block mt-8 lg:mx-0 px-8 py-3 rounded text-gray-700 hocus:bg-blue-900 hocus:text-gray-200 focus:shadow-outline border-b-0`}
  background-color: #ffed00; /* Custom background color */
`;

export default ({
  heading = <>Contact Us</>,
  description = "false",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <HeadingRow>
            <Heading>{heading}</Heading>
          </HeadingRow>
          <Text>
            {description && <h3>{description}</h3>}
            <Form action={formAction} method={formMethod}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <Input type="text" name="name" placeholder="Full Name" />
              <Input type="text" name="subject" placeholder="Subject" />
              <Textarea name="message" placeholder="Your Message Here" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </Text>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
