import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo_EP.png";
import LogoSpyders from "images/logo_spyders_2.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-icon.svg";

//PDFs import

import MobileAppPDF from "../../pdf/mobile_app_policy.pdf";
import WebsitePDF from "../../pdf/website_terms_of_use.pdf";
import CookiesPDF from "../../pdf/cookies_policy.pdf";

const Container = tw.div`relative bg-blue-900 text-gray-100 -mb-8 justify-center`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8 justify-center`;
const FiveColumns = tw.div`flex flex-wrap justify-between items-start px-4 py-0`;

const Column = tw.div`w-1/2 md:w-1/3 lg:w-1/4 mb-8 md:mb-0 text-sm sm:text-base text-center`;

const ColumnHeading = tw.h5`font-bold uppercase`;
// const FooterColumn = tw.div`w-1/2 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
// const FooterColumn = tw.div`inline-flex items-center text-sm mb-8 md:mb-0`;
const FooterColumn = tw.div`flex items-center justify-center md:justify-start text-sm mb-8 md:mb-0 px-10`;
const FooterRow = tw.div`flex flex-wrap justify-between items-center px-4 py-0`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center `;
const SpydersLogoImg = tw.img`w-8  `;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 lg:pl-2 text-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const poweredBySpyders = tw.div`pb-0 lg:pr-6 text-sm font-normal flex flex-col sm:flex-row justify-end items-center`;
const CopyrightAndCompanyInfoRow = tw.div`pb-0 lg:pr-6 text-sm font-normal flex flex-col sm:flex-row justify-end items-center`;
const CopyrightNotice = tw.div``;

const Divider = tw.div`my-8 border-b-2 border-gray-800`;
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
              <LogoContainer>
                <LogoText>Endell Point</LogoText>
              </LogoContainer>
              <SocialLinksContainer>
                <SocialLink href="https://www.facebook.com/endellpoint">
                  <FacebookIcon />
                </SocialLink>
                <SocialLink href="https://www.instagram.com/endellpoint">
                  <InstagramIcon />
                </SocialLink>
                <SocialLink href="https://www.linkedin.com/company/endell-point/">
                  <LinkedInIcon />
                </SocialLink>
              </SocialLinksContainer>
              {/* add text here with a bit of padding */}
              <p style={{margin: "14px"}}>#EndellPoint</p>
          </Column>
          <Column>
            <ColumnHeading>Terms & Conditions</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href={CookiesPDF} download="CookiesPDF" target="_blank" rel="noreferrer">
                  Cookies Policy
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={WebsitePDF} download="WebsitePDF" target="_blank" rel="noreferrer">
                  Website Terms of Use
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={MobileAppPDF} download="MobileAppPDF" target="_blank" rel="noreferrer">
                  Mobile App T&Cs
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Registered Office</ColumnHeading>
            <LinkList>
              <LinkListItem>
                Endell Point
                <br />
                Unit 6, 203 Kilburn High Rd.
                <br />
                London, NW6 7HY
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="mailto: wecare@endellpoint.co.uk">
                  {" "}
                  hello@endellpoint.co.uk
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <FooterRow>
          <FooterColumn>
            © 2024 Powered by&nbsp;
            <ColumnHeading>
              <Link href="https://spyders.tech">
                <SpydersLogoImg src={LogoSpyders} style={{ width: "60px" }} />
              </Link>
            </ColumnHeading>
          </FooterColumn>
          <FooterColumn>
            <CopyrightNotice>
              Endell Point LTD is registered in the UK, No. 13465282.
              Incorporated on June 18, 2021.
            </CopyrightNotice>
          </FooterColumn>
        </FooterRow>
      </Content>
    </Container>
  );
};
